exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-awards-page-js": () => import("./../../../src/templates/awards-page.js" /* webpackChunkName: "component---src-templates-awards-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-credits-page-js": () => import("./../../../src/templates/credits-page.js" /* webpackChunkName: "component---src-templates-credits-page-js" */),
  "component---src-templates-discography-page-js": () => import("./../../../src/templates/discography-page.js" /* webpackChunkName: "component---src-templates-discography-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-mediakit-page-js": () => import("./../../../src/templates/mediakit-page.js" /* webpackChunkName: "component---src-templates-mediakit-page-js" */),
  "component---src-templates-music-page-js": () => import("./../../../src/templates/music-page.js" /* webpackChunkName: "component---src-templates-music-page-js" */),
  "component---src-templates-music-post-js": () => import("./../../../src/templates/music-post.js" /* webpackChunkName: "component---src-templates-music-post-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-projects-page-js": () => import("./../../../src/templates/projects-page.js" /* webpackChunkName: "component---src-templates-projects-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-videos-page-js": () => import("./../../../src/templates/videos-page.js" /* webpackChunkName: "component---src-templates-videos-page-js" */)
}

