export const isBrowser = typeof window !== "undefined"

export const dotsClasses =
	"slick-dots column is-offset-5-desktop is-offset-7-widescreen is-7-desktop is-5-widescreen merged-dots"

export const VIDEOTYPES = {
	musicVideos: "music video",
	performances: "live performance",
	interviews: "interview",
}

export const VIDEOS_TO_SHOW = 6

export const toggleSortOrder = (sortStore, sortMutator) => {
	sortMutator({
		...sortStore,
		yearUp: !sortStore.yearUp,
		yearDown: !sortStore.yearDown,
	})
}

export const sortBy = (videos, order) => {
	// const sortedVideos = orderBy(videos, ["date"], order)
	const sortedVideos = orderBy(videos, "date", order)
	return sortedVideos
}

export const filterBy = (videos, filterBy, sortByTerm) => {
	// const filteredVideos = filter(videos, {
	// 	category: VIDEOTYPES[filterBy],
	// })
	const filteredVideos = videos.filter(
		(video) => video.category === VIDEOTYPES[filterBy]
	)

	const filteredAndSortedVideos =
		sortByTerm === "asc"
			? sortBy(filteredVideos, ["asc"])
			: sortBy(filteredVideos, ["desc"])

	return filteredAndSortedVideos
}

export const findSortOrder = (sortStore) => {
	const order = sortStore.yearDown ? "desc" : "asc"
	return order
}

export const checkVisibility = (isTrans, isVisible, classColor) => {
	let colorClass =
		classColor === "has-white-text" ? "has-black-text" : "has-white-text"
	if (isTrans && isVisible) {
		return `${classColor}`
	} else if (isVisible) {
		return `${colorClass}`
	} else {
		return `${colorClass} hidden`
	}
}

export function clearAllFiltersExceptSelected(filterTypes, category) {
	const resetFilters = Object.keys(filterTypes).reduce((filter, key) => {
		return { ...filter, [key]: key === category }
	}, {})

	return resetFilters
}

// Lodash alternatives
export const chunk = (input, size) => {
	return input.reduce((arr, item, idx) => {
		return idx % size === 0
			? [...arr, [item]]
			: [...arr.slice(0, -1), [...arr.slice(-1)[0], item]]
	}, [])
}

export const kebabize = (str) => {
	return str.split(" ").join("-").toLowerCase()
}

function basicSort(key) {
	return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0)
}

const orderByASC = (items, key) => {
	const orderedItems = items.concat().sort(basicSort(key))
	return orderedItems
}

const orderByDESC = (items, key) => {
	const orderedItems = items.concat().sort(basicSort(key)).reverse()
	return orderedItems
}

export const orderBy = (items, key, order) => {
	const orderedItems =
		order[0] === "desc" ? orderByDESC(items, key) : orderByASC(items, key)
	return orderedItems
}

export const decamelize = (str, separator) => {
	separator = typeof separator === "undefined" ? "_" : separator
	return str
		.replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
		.replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
		.toLowerCase()
}

export const dehyphenize = (str) => str.split("-").join(" ")
export const capitalize = (str) => {
	const lower = str.toLowerCase()
	const capitalizedStr = lower
		.split(" ")
		.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
		.join(" ")
	return capitalizedStr
	// return str.charAt(0).toUpperCase() + lower.slice(1)
}

// Generate Youtube Thumbnails
export function generateThumbnail({ image, title, link }) {
	const chosenImage = !!image
		? { imageWebp: null, imageJpg: image, alt: title }
		: {
				imageWebp: `https://i.ytimg.com/vi_webp/${link}/mqdefault.webp`,
				imageJpg: `https://i.ytimg.com/vi/${link}/mqdefault.jpg`,
				alt: title,
		  }
	return chosenImage
}

// Active Classes for Sort / Filter Dropdowns
export const ACTIVE_CLASS = {
	open: {
		sortClass: "is-active",
	},
	closed: {
		sortClass: "",
	},
}
