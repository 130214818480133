/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// Supports weights 400-700
import "@fontsource-variable/instrument-sans"
import "@fontsource/montserrat/600.css"

import "./src/assets/all.sass"
import "./src/assets/ytlite.sass"

import { isBrowser } from "./src/utils"

export const onServiceWorkerUpdateReady = () => {
	if (isBrowser) {
		const answer = window.confirm(
			`The website has been updated. ` +
				`Please reload to view the latest version`
		)
		if (answer === true) {
			window.location.reload()
		}
	}
}
